import { get } from 'svelte/store';
import { app, setLoading, setGlobalStore, setMetaStore } from './store';
import { COOKIES, COOKIES_CONSENT, COUNTRY_CODES_GDPR } from '$lib/utils/constants';
import { userDetectionService } from '$lib/utils/user-detection';
import Cookies from 'js-cookie';

/**
 * Represents a mapping of cookie(or source object) keys to corresponding property names
 * for meta store.
 * @type {{[key: string]: string}}
 */
const META_VAR_MAP = {
	'cloudfront-is-ios-viewer': 'isIosViewer',
	'cloudfront-viewer-postal-code': 'viewerPostalCode',
	'cloudfront-viewer-longitude': 'viewerLongitude',
	'cloudfront-viewer-latitude': 'viewerLatitude',
	'cloudfront-viewer-asn': 'viewerAsn',
	'cloudfront-viewer-metro-code': 'viewerMetroCode',
	'cloudfront-viewer-country-region': 'viewerCountryRegion',
	'cloudfront-is-android-viewer': 'isAndroidViewer',
	'cloudfront-viewer-city': 'viewerCity',
	'cloudfront-viewer-country-name': 'viewerCountryName',
	'cloudfront-viewer-tls': 'viewerTls',
	'cloudfront-forwarded-proto': 'forwardedProto',
	'cloudfront-viewer-country-region-name': 'viewerCountryRegionName',
	'cloudfront-viewer-country': 'viewerCountry',
	'cloudfront-viewer-address': 'viewerAddress',
	'cloudfront-is-desktop-viewer': 'isDesktopViewer',
	'cloudfront-is-mobile-viewer': 'isMobileViewer',
	'cloudfront-viewer-http-version': 'viewerHttpVersion',
	'cloudfront-viewer-time-zone': 'viewerTimeZone',
	'cloudfront-is-smarttv-viewer': 'isSmarttvViewer',
	'cloudfront-is-tablet-viewer': 'isTabletViewer',
	'x-aofl-app-version': 'appVersion',
	'x-aofl-in-app': 'inApp',
	'x-aofl-experiment-info': 'experimentInfo',
	'x-aofl-app-name': 'appName',
	'x-aofl-app-store-name': 'appStoreName',
	'x-aofl-is-android-app': 'isAndroidApp',
	'x-aofl-is-smart-tv-app': 'isSmartTvApp',
	'x-aofl-is-amazon-app': 'isAmazonApp',
	'x-aofl-is-ios-app': 'isIosApp',
	'x-aofl-is-samsung-app': 'isSamsungApp',
	'x-aofl-is-mac': 'isMac',
	'x-aofl-is-windows': 'isWindows',
	'x-aofl-is-link-from-app': 'isLinkFromApp',
	'x-aofl-is-amazon-viewer': 'isAmazonViewer'
};

/**
 * @param {object} opts - options
 * @param {boolean} [opts.darkMode] - set true for opaque background
 * @param {boolean} [opts.timerMode] - set true for automatically stopping load screen in 500 ms.
 * @returns {void}
 */
export const startLoading = (
	{ darkMode, timerMode } = {
		darkMode: false,
		timerMode: false
	}
) => {
	if (!get(app).global.loading) {
		setLoading(true, darkMode ?? false);
		if (timerMode) {
			setTimeout(() => {
				stopLoading();
			}, 1000);
		}
	}
};

export const stopLoading = (loadingDarkMode = false) => {
	setLoading(false, loadingDarkMode);
};

/**
 * Updates the meta object in the store with the provided metaValues.
 * @param {{[key:string]: string}} metaValues - Meta values provided by external sources(cloudfront) about the user
 * @returns {void}
 */
export const setMeta = (metaValues) => {
	/** @type {{[key: string]: string | boolean | null }} */
	const updatedMeta = {};

	for (const key in metaValues) {
		if (META_VAR_MAP[key]) {
			updatedMeta[META_VAR_MAP[key]] = metaValues[key];
		}
	}
	// Rehydrating meta values with client side calculation (browser-based),
	// this is to allow for any missed determination for flags
	// added by a browser (or other client like ABCm App) after
	// the initial request.
	const clientSideMetaValues = userDetectionService({
		navigator: navigator,
		cookies: Cookies.get(),
		urlSearchParams: new URLSearchParams(window?.location?.search)
	});

	setMetaStore({ ...updatedMeta, ...clientSideMetaValues });
};

/**
 * Initializes cookies and store values.
 * @returns {void}
 */
export const initializeCookiesStore = () => {
	const analyticsCookie = Cookies.get(COOKIES_CONSENT.ANALYTICS) ?? 'true';
	const targetingCookie = Cookies.get(COOKIES_CONSENT.TARGETING) ?? 'true';

	Cookies.set(COOKIES_CONSENT.ANALYTICS, analyticsCookie, COOKIES.CONFIG);
	Cookies.set(COOKIES_CONSENT.TARGETING, targetingCookie, COOKIES.CONFIG);

	setGlobalStore({
		analyticsCookiesEnabled: analyticsCookie === 'true',
		targetingCookiesEnabled: targetingCookie === 'true'
	});
};

/**
 * Sets the consent status for analytics and targeting cookies.
 * @param {boolean} value - The consent value for analytics cookies.
 * @returns {void}
 */
export const setCookieConsent = (value) => {
	Cookies.set(COOKIES_CONSENT.ANALYTICS, value.toString());
	Cookies.set(COOKIES_CONSENT.TARGETING, value.toString());

	setGlobalStore({
		analyticsCookiesEnabled: value,
		targetingCookiesEnabled: value
	});
};

/**
 * Sets the GDPR visibility state based on the viewer's country.
 * @param {string | boolean} viewerCountry - The country code of the viewer (e.g., 'US', 'GB').
 * @param {boolean} setCookie - Optional parameter to set a cookie and hide the GDPR banner.
 * @returns {void}
 */
export const setGdprVisibility = (viewerCountry, setCookie = false) => {
	if (Cookies.get(COOKIES.GDPR)) return;

	const isGdprCountry =
		typeof viewerCountry === 'boolean'
			? viewerCountry
			: COUNTRY_CODES_GDPR[viewerCountry] !== undefined;
	if (isGdprCountry) setGlobalStore({ gdprVisible: isGdprCountry });

	if (setCookie) {
		setGlobalStore({
			gdprVisible: false
		});
		Cookies.set(COOKIES.GDPR, 'hide', COOKIES.CONFIG);
	}
};

export const setUserLocale = (localeCode = 'es-US') => {
	setMetaStore({ localeCode });
};
